import React from 'react';
import dataFooterMenu from '../../data/footer/footerMenu';


function getIcon(icon) {
        return <img style={{width:'20px'}} src={`assets/icons/png/${icon}.png`} alt='tiktok'></img>
}

const FooterMenu = () => {
    return (
        <nav className="menu-secondary">
            <ul className="nav">
                {dataFooterMenu && dataFooterMenu.map((item, key) => {
                    return (
                        <li key={key} className="nav-item">
                            <a title={item.title} href={item.link} target='_blank'>
                                {getIcon(item.icon)}
                            </a>
                        </li>
                    );
                })}
            </ul>
        </nav>
    );
};

export default FooterMenu;
