import React from 'react';
import { useTranslation } from "react-i18next";

const BackToHome = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="button">
            <a title="Back to homepage" className="btn btn-lg btn-link border-0 p-0 min-w-auto link-no-space text-uppercase" href={ process.env.PUBLIC_URL + "/" }>{t('shared.backToHome')}</a>
        </div>
    );
};

export default BackToHome;
