import React from 'react';
import { useTranslation } from "react-i18next";

const Products = () => {
    const { t, i18n } = useTranslation();

    return (
        <section id="about-us" className="block spacer p-top-xl p-bottom-xl">
            <div className="wrapper">
                <div className="row gutter-width-sm justify-content-end text-right">
                    <div className="col-xl-8 col-lg-8 col-md-12 col-sm-12">
                        <div className="title">
                            <h2 className="text-uppercase">{t('home.productsTitle')}</h2>
                        </div>
                    </div>
                </div>

                <div className="row gutter-width-sm">
                    <div className="col-xl-7 col-lg-7 col-md-12 col-sm-12">
                        <div className="description">
                            <p>{t('home.productsText')}</p>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    );
};

export default Products;
