import React from 'react';
import { useTranslation } from "react-i18next";

export default function ServicesHome() {
    const { t, i18n } = useTranslation();

    return (
        <section id="services" className="block">
            <div className="adv-slider-services" style={{cursor:'pointer'}}>
                <div className="adv-swiper-container">
                    <div className="row no-gutters adv-swiper-wrapper services-items clearfix">
                        <a title="Haircut & Shave" className="px-0 py-3 py-lg-0 px-lg-3 col-12 col-lg-4 adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside#haircutandshave"}>
                            <div className="services-item-content">
                                <h3 className="services-item-t-head">{t('shared.haircutAndShave')}</h3>

                                <span className="btn btn-lg btn-link border-0 p-0 min-w-auto link-no-space">{t('shared.readMore')}</span>
                            </div>

                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="assets/img/placeholder/model13.jpg" alt="Haircut & Shave" />
                                </div>
                            </div>

                        </a>

                        <a title="Haircut" className="px-0 py-3 py-lg-0 px-lg-3 col-12 col-lg-4 adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside#haircut"}>
                            <div className="services-item-content">
                                <h3 className="services-item-t-head">{t('shared.haircut')}</h3>

                                <span className="btn btn-lg btn-link border-0 p-0 min-w-auto link-no-space">{t('shared.readMore')}</span>
                            </div>

                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="assets/img/placeholder/model4.jpg" alt="Haircut" />
                                </div>
                            </div>

                        </a>

                        <a title="Beard" className="px-0 py-3 py-lg-0 px-lg-3 col-12 col-lg-4 adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside#beard"}>
                            <div className="services-item-content">
                                <h3 className="services-item-t-head">{t('shared.beard')}</h3>

                                <span className="btn btn-lg btn-link border-0 p-0 min-w-auto link-no-space">{t('shared.readMore')}</span>
                            </div>

                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src="assets/img/placeholder/model5.jpg" alt="Beard" />
                                </div>
                            </div>

                        </a>

                        {/*
                            < a title="Beard Trim" className="adv-swiper-slide services-item" href={process.env.PUBLIC_URL + "/services-inside"}>
                        <div className="services-item-content">
                            <h3 className="services-item-t-head">Beard Trim</h3>

                            <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto link-no-space">read more</span>
                        </div>

                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="assets/img/placeholder/780x500.jpg" alt="Beard Trim" />
                            </div>
                        </div>

                        <div className="img-bg-color"></div>
                    </a> */}
                    </div>

                    {/* <div className="adv-2-swiper-button-prev">
                        <span>
                            <span className="btn btn-lg btn-before-dot btn-link border-0 p-0 min-w-auto">Previous</span>
                        </span>
                    </div>

                    <div className="adv-2-swiper-button-next">
                        <span>
                            <span className="btn btn-lg btn-after-dot btn-link border-0 p-0 min-w-auto">Next</span>
                        </span>
                    </div> */}
                </div>
            </div>
        </section >
    );
}