import React from 'react';
import HeaderLogo from '../../blocks/logo/HeaderLogo';
import MenuModal from '../../components/modal/MenuModal';
import { useTranslation } from "react-i18next";
import { LANGUAGES } from '../../data/language/index';

export default function Header({ logoColor }) {
    const { t, i18n } = useTranslation();

    const changeLanguage = (e) => {
        i18n.changeLanguage(e.target.value);
    };

    return (
        <header id="header" className="site-header">
            <div className="wrapper">
                <div className="header-content d-flex justify-content-between">
                    <div className="header-left align-self-center">
                        <div className='d-none d-md-block'>
                            <HeaderLogo logoColor={logoColor} />
                        </div>
                    </div>

                    <div className="header-center align-self-center">
                        <div className='d-block d-md-none'>
                            <HeaderLogo logoColor={logoColor} />
                        </div>
                        <div className='d-none d-md-block title'>
                            <button className='btn btn-lg btn-link border-0 p-0 min-w-auto text-uppercase px-2' style={{ fontSize: '1.5rem' }}>
                                <a title="Home" href={process.env.PUBLIC_URL + "/"}>Home</a>
                            </button>
                            <button className='btn btn-lg btn-link border-0 p-0 min-w-auto text-uppercase px-2' style={{ fontSize: '1.5rem' }}>
                                <a title="About us" href={process.env.PUBLIC_URL + "/about-us"}>{t('header.aboutUs')}</a>
                            </button>
                            <button className='btn btn-lg btn-link border-0 p-0 min-w-auto text-uppercase  px-2' style={{ fontSize: '1.5rem' }}>
                                <a title="Services" href={process.env.PUBLIC_URL + "/services-inside"}>{t('header.services')}</a>
                            </button>
                            <button className='btn btn-lg btn-link border-0 p-0 min-w-auto text-uppercase  px-2' style={{ fontSize: '1.5rem' }}>
                                <a title="Gallery" href={process.env.PUBLIC_URL + "/gallery"}>{t('header.gallery')}</a>
                            </button>
                            <button className='btn btn-lg btn-link border-0 p-0 min-w-auto text-uppercase  px-2' style={{ fontSize: '1.5rem' }}>
                                <a title="Contacts" href={process.env.PUBLIC_URL + "/contacts"}>{t('header.contacts')}</a>
                            </button>
                        </div>
                    </div>

                    <div className="header-right d-flex justify-content-end align-items-center">
                        <div className='d-block d-md-none'>
                            <div className="d-flex align-items-center">
                                <MenuModal />
                            </div>
                        </div>
                        <div className="d-none d-md-block languages pr-4">
                            <select value={i18n.language} className="form-control" id="exampleFormControlSelect1" onChange={changeLanguage} style={{ cursor: 'pointer', borderRadius: '30px', paddingRight: '40px', color: logoColor === 'dark' ? 'black' : 'white', borderColor: logoColor === 'dark' ? 'black' : 'white' }}>
                                {LANGUAGES.map(({ code, label }) => (
                                    <option id='language' key={code} className="lang-item pr-2" value={code} style={{ color: 'black' }}>
                                        {code.toUpperCase()}
                                    </option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
            </div>
        </header>
    );
};