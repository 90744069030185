import React, { Fragment, useEffect } from 'react';
import { Helmet } from "react-helmet";
import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import PageTitleServicesInside from '../blocks/page-title/PageTitleServicesInside'
import Footer from '../blocks/footer/Footer';
import BackToServices from '../components/button/BackToServices';
import { useLocation } from 'react-router-dom';
import { useTranslation } from "react-i18next";


export default function ServicesInside() {
    document.body.classList.add('single');
    document.body.classList.add('single-adveits_services');
    const location = useLocation();
    const { t, i18n } = useTranslation();

    useEffect(() => {
        const hash = location.hash;
        if (hash) {
            const element = document.querySelector(hash);
            if (element) {
                element.scrollIntoView({ behavior: 'smooth' });
            }
        } else {
            window.scrollTo({ top: 0, behavior: 'smooth' });
        }
    }, [location]);

    return (
        <Fragment>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Service inside | {process.env.REACT_APP_WEBSITE_NAME.toString()}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Il miglior servizio di taglio parrucchiere, barbiere e styling per uomo nel cuore di Tradate. Scopri i nostri trattamenti di barba e capelli maschili per un look impeccabile." />
                <meta name="keywords" content="Barbiere Uomo, Parrucchiere Maschile, Taglio Capelli, Barba, Rasatura, Barbiere, Parrucchiere" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleServicesInside />

                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div id="single">
                                <div id="single-content">
                                    <div id="img" className="block">
                                        <div className="img-no-wrap-1">
                                            <div className="img object-fit">
                                                <div className="object-fit-cover">
                                                    <img src="assets/img/bg/bg1-minV2.jpg" alt="Services" />
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    <div id="description" className="block spacer p-top-xl">
                                        <div className="description">
                                            <p>{t('ourServices.ourServicesText1')}</p>
                                            <p>{t('ourServices.ourServicesText2')}</p>
                                        </div>
                                    </div>

                                    <div id="price-list" className="block spacer p-top-xl">
                                        <div className="title">
                                            <h2 className="hr text-uppercase">{t('ourServices.priceList')}</h2>
                                        </div>

                                        <div className="price-list">
                                            <div className="price-list-items">
                                                <div className="price-list-item" id='haircutandshave'>
                                                    <div className="price-list-item-title">
                                                        <h3>{t('shared.haircutAndShave')}</h3>
                                                    </div>

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircutAndShave.text1')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>12 €</span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="price-list-item" id='haircut'>
                                                    <div className="price-list-item-title">
                                                        <h3>{t('shared.haircut')}</h3>
                                                    </div>

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text1')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>28 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text2')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>25 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text3')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>18 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text4')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>22 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text5')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>19 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text6')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>32 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text7')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>18 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text10')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>21 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text8')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>20 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.haircut.text9')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>23 €</span>
                                                        </li>
                                                    </ul>
                                                </div>

                                                <div className="price-list-item" id='beard'>
                                                    <div className="price-list-item-title">
                                                        <h3>{t('shared.beard')}</h3>
                                                    </div>

                                                    <ul className="price-list-item-list-group list-group">
                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.beard.text1')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>15 €</span>
                                                        </li>

                                                        <li className="list-group-item d-flex justify-content-between align-items-center">
                                                            <span className="list-group-title">{t('ourServices.beard.text2')}</span>
                                                            <span className="list-group-price" style={{minWidth:'30px'}}>18 €</span>
                                                        </li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <BackToServices /> */}
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>
            <Footer noBorderTop={true} />
        </Fragment>
    );
};
