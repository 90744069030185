import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import HttpApi from 'i18next-http-backend';
import LanguageDetector from 'i18next-browser-languagedetector';

i18n
  .use(HttpApi) // Enables loading translation files over HTTP.
  .use(LanguageDetector) // Detects user language.
  .use(initReactI18next) // Initializes the react-i18next plugin.
  .init({
    lng: 'it',
    supportedLngs: ['it', 'en'], // Languages we're supporting.
    fallbackLng: "it", // Fallback language if user's language isn't supported.
    detection: {
      order: ['cookie', 'htmlTag', 'localStorage', 'path', 'subdomain'], // Order of language detection.
      caches: ['cookie'], // Cache the detected language in cookies.
    },
    backend: {
      loadPath: '/assets/locales/{{lng}}/translation.json', // Path to the translation files.
    },
  });

export default i18n;