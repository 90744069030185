import React from 'react';
import { useTranslation } from "react-i18next";

const Contacts = () => {
    const { t, i18n } = useTranslation();
    return (
        <div className="contacts">
            <div className="contacts-items">
                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>
                            <a href="mailto:info@fchairstylistbarber.it">info@fchairstylistbarber.it</a>
                        </p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>Email</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>Corso G.Matteotti, 45 - 21049 Tradate (VA)</p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>{t('shared.address')}</h6>
                    </div>
                </div>

                <div className="contacts-item">
                    <div className="contacts-item-description">
                        <p>
                            <a href="tel:00393892871777">+39 3892871777</a>
                        </p>
                    </div>

                    <div className="contacts-item-title">
                        <h6>{t('shared.phone')}</h6>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Contacts;
