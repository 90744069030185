import React, { Component } from 'react';
import { useTranslation } from "react-i18next";

export default function AboutReviews() {
    const { t, i18n } = useTranslation();

    return (
        <div id="testimonials" className="block spacer p-top-xl">
            <div className="title">
                <h2 className="hr text-uppercase">{t('aboutUs.goal')}</h2>
            </div>

            <div className="adv-slider-reviews">
                <div className="adv-slider-reviews-img">
                    <img src="assets/img/demo/32_img.png" alt="Icon" />
                </div>

                <div className="adv-swiper-container reviews-text">
                    <div className="adv-swiper-wrapper reviews-text-items">
                        <div className="adv-swiper-slide reviews-text-item">
                            <div className="reviews-text-item-content">
                                <h3>{t('aboutUs.goalText')}</h3>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}
