import React from 'react';
import { useTranslation } from "react-i18next";

export default function ModalMenuPrimary() {
    const { t, i18n } = useTranslation();

    return (
        <nav className="menu-primary">
            <ul className="nav">
                <li className={"nav-item" + (window.location.pathname === '/' ? " current-nav-item" : "")}>
                    <a title="Home" href={process.env.PUBLIC_URL + "/"}>{t('header.home')}</a>
                </li>

                <li className={"nav-item" + (window.location.pathname === '/about' ? " current-nav-item" : "")}>
                    <a title="About us" href={process.env.PUBLIC_URL + "/about-us"}>{t('header.aboutUs')}</a>
                </li>

                <li className={"nav-item" + (window.location.pathname === '/services' ? " current-nav-item" : "")}>
                    <a title="Services" href={process.env.PUBLIC_URL + "/services-inside"}>{t('header.services')}</a>
                </li>

                <li className={"nav-item" + (window.location.pathname === '/gallery' ? " current-nav-item" : "")}>
                    <a title="Gallery" href={process.env.PUBLIC_URL + "/gallery"}>{t('header.gallery')}</a>
                </li>

                <li className={"nav-item" + (window.location.pathname === '/contacts' ? " current-nav-item" : "")}>
                    <a title="Contacts" href={process.env.PUBLIC_URL + "/contacts"}>{t('header.contacts')}</a>
                </li>
            </ul>
        </nav>
    );
};