import React, { Fragment, useEffect, useRef } from 'react';
import { Helmet } from "react-helmet";
import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import PageTitleContacts from '../blocks/page-title/PageTitleContacts';
import ContactForm from '../components/form/ContactForm';
import { useTranslation } from "react-i18next";
import ContactsInside from '../blocks/contacts/Contacts';
import GoogleMapsStyle from '../data/google-maps/googleMapsStyle.json';

export default function Contacts() {
    const { t, i18n } = useTranslation();
    const mapRef = useRef();
    let map = null;
    useEffect(() => {
        generateMaps();
    })

    function generateMaps() {
        // 189 Bedford Ave, Brooklyn, NY 11211, United States
        var address_latlng = new window.google.maps.LatLng(45.71569867285944, 8.902466249077127);
        // load finished
        map = new window.google.maps.Map(mapRef.current, {
            center: address_latlng,
            zoom: 20,
            zoomControl: false,
            mapTypeControl: false,
            scaleControl: false,
            streetViewControl: false,
            rotateControl: false,
            fullscreenControl: false,
            styles: GoogleMapsStyle
        });

        var icon = {
            url: '../../assets/img/demo/marker.png',
            size: new window.google.maps.Size(112, 118)
        };

        map.setCenter(address_latlng);

        // new window.google.maps.Marker({
        //     position: address_latlng,
        //     map: map,
        //     icon: icon
        // });
    }
    document.body.classList.add('page');

    return (
        <Fragment>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Contacts | {process.env.REACT_APP_WEBSITE_NAME.toString()}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Il miglior servizio di taglio parrucchiere, barbiere e styling per uomo nel cuore di Tradate. Scopri i nostri trattamenti di barba e capelli maschili per un look impeccabile." />
                <meta name="keywords" content="Barbiere Uomo, Parrucchiere Maschile, Taglio Capelli, Barba, Rasatura, Barbiere, Parrucchiere" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>

            <Loading />

            <Header logoColor="dark" />

            <main id="main" className="site-main">
                <PageTitleContacts />
                <div id="page-content" className="spacer p-top-xl">
                    <div className="wrapper">
                        <div className="content">
                            <div className="clearfix">
                                {/* <GoogleMaps /> */}
                                <div id="google-maps" ref={mapRef}></div>

                                <div className="spacer p-top-md text-center">
                                    {/* <div className="title">
                                        <h2 className="text-uppercase">Contact Us</h2>
                                    </div> */}

                                    <div className="row gutter-width-sm with-pb-xl spacer text-center">
                                        <div className="col pb-5">
                                            <ContactsInside />
                                        </div>

                                        {/* <div className="col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                            <div className="contact-form-shortcode">
                                                <ContactForm />
                                            </div>
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div id="testimonials" className="block spacer p-top-xl">
                            <div className="title">
                                <h2 className="hr text-uppercase">{t('shared.parking')}</h2>
                            </div>
                            <div className="adv-slider-reviews">
                                <div className="adv-swiper-container reviews-text" style={{ overflow: 'visible' }}>
                                    <div className="adv-swiper-wrapper reviews-text-items">
                                        <div className="adv-swiper-slide reviews-text-item">
                                            <div className="reviews-text-item-content">

                                                <ul className='normal' style={{ listStyle: 'circle', color: 'black' }}>
                                                    <li style={{ fontSize: '35px' }}>
                                                        <h3>{t('contacts.parking1')}</h3>
                                                    </li>
                                                    <li style={{ fontSize: '35px' }}>
                                                        <h3>{t('contacts.parking2')}</h3>
                                                    </li>
                                                </ul>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};