import React, { Component, Fragment, useState } from 'react';
import FsLightbox from "fslightbox-react";

export default function SwipeBoxComponent() {
    const [toggler, setToggler] = useState(false);
    const images = [
        'assets/img/placeholder/model1.jpg',
        'assets/img/placeholder/model2.jpg',
        'assets/img/placeholder/model3.jpg',
        'assets/img/placeholder/model4.jpg',
        'assets/img/placeholder/model5.jpg',
        'assets/img/placeholder/model6.jpg',
        'assets/img/placeholder/model7.jpg',
        'assets/img/placeholder/model8.jpg',
        'assets/img/placeholder/model11.jpg',
        'assets/img/placeholder/model12.jpg',
        'assets/img/placeholder/model9.jpg',
        'assets/img/placeholder/model10.jpg',
        'assets/img/placeholder/model13.jpg',
    ]
    return (
        <Fragment>
            <FsLightbox
                toggler={toggler}
                sources={images}
            />
            {
                images.map(image =>
                    <div className="col-12 col-md-6 col-lg-4" key={image}>
                        <a onClick={() => setToggler(!toggler)} title="FC Hair & Stylist Barber" className="gallery-item swipebox">
                            <div className="img object-fit">
                                <div className="object-fit-cover">
                                    <img src={image} alt={image} />
                                </div>
                            </div>

                            <div className="img-bg-color"></div>
                        </a>
                    </div>
                )
            }
            {/* <div className="col-12 col-md-6 col-lg-4">
                <a onClick={() => setToggler(!toggler)} title="Face & Body | Image 1" className="gallery-item swipebox">
                    <div className="img object-fit">
                        <div className="object-fit-cover">
                            <img src="assets/img/placeholder/model1.jpg" alt="Face & Body" />
                        </div>
                    </div>

                    <div className="img-bg-color"></div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
                <a onClick={() => setToggler(!toggler)} title="Hair Treatments | Image 2" className="gallery-item swipebox">
                    <div className="img object-fit">
                        <div className="object-fit-cover">
                            <img src="assets/img/placeholder/model2.jpg" alt="Hair Treatments" />
                        </div>
                    </div>

                    <div className="img-bg-color"></div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
                <a onClick={() => setToggler(!toggler)} title="Hands & Feets | Image 3" className="gallery-item swipebox">
                    <div className="img object-fit">
                        <div className="object-fit-cover">
                            <img src="assets/img/placeholder/model3.jpg" alt="Hands & Feets" />
                        </div>
                    </div>

                    <div className="img-bg-color"></div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
                <a onClick={() => setToggler(!toggler)} title="Haircuts for Men | Image 4" className="gallery-item swipebox">
                    <div className="img object-fit">
                        <div className="object-fit-cover">
                            <img src="assets/img/placeholder/780x500.jpg" alt="Haircuts for Men" />
                        </div>
                    </div>

                    <div className="img-bg-color"></div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
                <a onClick={() => setToggler(!toggler)} title="Hair | Image 5" className="gallery-item swipebox">
                    <div className="img object-fit">
                        <div className="object-fit-cover">
                            <img src="assets/img/placeholder/780x500.jpg" alt="Hair" />
                        </div>
                    </div>

                    <div className="img-bg-color"></div>
                </a>
            </div>

            <div className="col-12 col-md-6 col-lg-4">
                <a onClick={() => setToggler(!toggler)} title="Manicure | Image 6" className="gallery-item swipebox">
                    <div className="img object-fit">
                        <div className="object-fit-cover">
                            <img src="assets/img/placeholder/780x500.jpg" alt="Manicure" />
                        </div>
                    </div>

                    <div className="img-bg-color"></div>
                </a>
            </div> */}
        </Fragment>
    );
}