import React from 'react';
import { LANGUAGES } from "../../data/language";
import { useTranslation } from "react-i18next";


export default function ModalMenuLanguages({closeMenu}) {
    const { t, i18n } = useTranslation();

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
    };

    return (
        <nav className="languages d-flex justify-content-center" style={{paddingTop:'3rem'}}>
            <ul className="nav">
                {/* <li className="lang-item current-lang">
                    <a title="en" href={ process.env.PUBLIC_URL + "/" }>en</a>
                </li> */}
                {LANGUAGES.map(({ code, label }) => (
                    <li id='language' key={code} className="lang-item">
                        <a title={code} href={"/"} onClick={(e) => { e.preventDefault(); changeLanguage(code);closeMenu() }}>{code}</a>
                    </li>
                ))}
            </ul>
        </nav>
    );
};
