import React from 'react';
import { useTranslation } from "react-i18next";

const AboutOurTeam = () => {
    const { t, i18n } = useTranslation();

    return (
        <div id="our-team" className="block spacer">
            <div className="title">
                <h2 className="hr">{t('aboutUs.ourTeam')}</h2>
            </div>

            <div className="row gutter-width-sm with-pb-md justify-content-center">
                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="assets/img/placeholder/507x600.jpg" alt="Federico Caon" />
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position">
                                <p>{t('aboutUs.ceoAndStylist')}</p>
                            </div>

                            <h4 className="team-member-t-head">Federico Caon</h4>

                            <div className="team-member-description">
                                <p>{t('aboutUs.federicoText')}</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="TikTok" href="https://www.tiktok.com/@fchairstylistbarber"><img style={{ width: '20px' }} src='assets/icons/png/tiktok.png' alt='tiktok'></img></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/fchairstylistbarber"><img style={{ width: '20px' }} src='assets/icons/png/instagram.png' alt='instagram'></img></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                {/* <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="assets/img/placeholder/507x600.jpg" alt="Andrew Nick" />
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position">
                                <p>Beard Stylist</p>
                            </div>

                            <h4 className="team-member-t-head">Andrew Nick</h4>

                            <div className="team-member-description">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots.</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/adveits/"><i className="fab fa-facebook-f"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="LinkedIn" href="https://www.linkedin.com/company/18135051/"><i className="fab fa-linkedin-in"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/adveits/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div>

                <div className="col-sm-6 col-md-6 col-lg-4 col-xl-4">
                    <div className="team-member">
                        <div className="img object-fit">
                            <div className="object-fit-cover">
                                <img src="assets/img/placeholder/507x600.jpg" alt="Tom Henders" />
                            </div>
                        </div>

                        <div className="team-member-content">
                            <div className="team-member-position">
                                <p>Hair Stylist</p>
                            </div>

                            <h4 className="team-member-t-head">Tom Henders</h4>

                            <div className="team-member-description">
                                <p>Contrary to popular belief, Lorem Ipsum is not simply random text. It has roots.</p>
                            </div>
                        </div>

                        <nav className="team-member-nav-items">
                            <ul className="nav">
                                <li className="nav-item">
                                    <a title="Facebook" href="https://www.facebook.com/adveits/"><i className="fab fa-facebook-f"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="LinkedIn" href="https://www.linkedin.com/company/18135051/"><i className="fab fa-linkedin-in"></i></a>
                                </li>

                                <li className="nav-item">
                                    <a title="Instagram" href="https://www.instagram.com/adveits/"><i className="fab fa-instagram"></i></a>
                                </li>
                            </ul>
                        </nav>
                    </div>
                </div> */}
            </div>
        </div>
    );
};

export default AboutOurTeam;
