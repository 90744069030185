import React from 'react';
import { Link } from 'react-scroll';
import { useTranslation } from "react-i18next";

export default function PageTitleHome() {
    const { t, i18n } = useTranslation();

    return (
        <div style={{ height: '100vh', position: 'relative' }}>
            <section id="page-title" className="block with-img" style={{ backgroundImage: 'url(assets/img/bg/bg5-min.jpg)', backgroundSize: 'cover' }}>
                <div className="wrapper text-center d-flex">
                    <div className="align-self-center w-100" style={{marginTop:'340px'}}>
                        <div className="title">
                            <h1 className="large text-uppercase">{process.env.REACT_APP_WEBSITE_NAME}</h1>
                        </div>
                        <div className="description spacer p-top-lg">
                            <p>{t('home.barberSince')} 2010</p>
                        </div>

                        <Link href="#about-us"
                            to="about-us"
                            spy={true}
                            smooth={true}
                            className="btn btn-lg btn-link border-0 p-0 min-w-auto link-no-space text-uppercase scroll-to-id position-absolute"
                            style={{ bottom: '40px' }}>
                            <i className="fa fa-arrow-down" style={{ fontSize: '2rem' }}></i>
                        </Link>
                    </div>
                </div>
                <div className="page-title-bg-color"></div>
            </section>
        </div>
    );
};