import React, { useEffect } from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Home from './pages/Home';
import About from './pages/About';
import Contacts from './pages/Contacts';
import News from './pages/News';
import NewsSinglePost from './pages/NewsSinglePost';
import Gallery from './pages/Gallery';
import SearchResults from './pages/SearchResults';
import Services from './pages/Services';
import ServicesInside from './pages/ServicesInside';
import Page404 from './pages/404';
import UserInterface from './pages/UserInterface';

function App() {
    return (
        <Router>
            <Routes>
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/"}`}
                    element={<Home />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/about-us"}`}
                    element={<About />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/contacts"}`}
                    element={<Contacts />}
                />
                {/* <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/news"}`}
                    element={<News />}
                /> */}
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/gallery"}`}
                    element={<Gallery />}
                />
                {/* <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/news-single-post"}`}
                    element={<NewsSinglePost />}
                />
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/search-results"}`}
                    element={<SearchResults />}
                /> */}
                {/* <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services"}`}
                    element={<Services />}
                /> */}
                {/* <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/ui"}`}
                    element={<UserInterface />}
                /> */}
                <Route
                    exact
                    path={`${process.env.PUBLIC_URL + "/services-inside"}`}
                    element={<ServicesInside />}
                />
                <Route path="*" element={<Page404 />} />
            </Routes>
        </Router>
    );
}

export default App;
