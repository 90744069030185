import { func } from 'prop-types';
import React, { useState } from 'react';

const Copyright = () => {
    // 🔡 Characters to cycle trough
    let allowedCharacters = ['a','b','c','d','e','f','g','h','i','j','k','l','m','n','o','p','q','r','s','t','u','v','w','y','z'];
    const [isInProgress, setIsInProgress] = useState(false)

    // 🎁 Function to return random character
    function getRandomCharacter() {
        const randomIndex = Math.floor(Math.random() * allowedCharacters.length);
        return allowedCharacters[randomIndex];
    }
    // 🏭 Creates new event handler with a private variable

    // 👇 Event handler implementation
    function handleHoverEvent(e) {
        if (isInProgress) {
            return;
        }

        setIsInProgress(true);
        const text = e.target.innerHTML;
        const randomizedText = text.split('').map(getRandomCharacter).join('');
        let timeouts = [];

        for (let i = 0; i < text.length; i++) {
            let timeoutId = setTimeout(() => {
                const nextIndex = i + 1;
                e.target.innerHTML = `${text.substring(0, nextIndex)}${randomizedText.substring(nextIndex)}`;

                if (nextIndex === text.length) {
                    setIsInProgress(false);
                    timeouts.forEach(clearTimeout);  // Pulisce tutti i timeout al termine dell'animazione
                    timeouts = [];  // Svuota l'array dei timeout
                }
            }, i * 20);

            timeouts.push(timeoutId);  // Aggiunge l'ID del timeout all'array
        }

        e.target.dataset.timeouts = JSON.stringify(timeouts);  // Memorizza gli ID dei timeout come attributo dell'elemento
    }

    // const removeHandleHoverEvent = (e) => {
    //     // Recupera e cancella tutti i timeout attivi
    //     const timeouts = JSON.parse(e.target.dataset.timeouts || '[]');
    //     timeouts.forEach(clearTimeout);

    //     e.target.innerHTML = "Made with ❤️ Fabio";
    //     setIsInProgress(false);
    // }

    return (
        <div className="copyright" style={{ color: '#262626' }}>
            <p>
                <u>
                    <a href='https://www.iubenda.com/privacy-policy/50206159' target='_blank'>Privacy Policy
                    </a>
                </u> |
                <u>
                    <a href='https://www.iubenda.com/privacy-policy/50206159/cookie-policy' target='_blank'>Cookie Policy
                    </a>
                </u> |
                <a className='fabio-link' href='https://fabiolaganga.com' target='_blank' onMouseEnter={(e) => handleHoverEvent(e)}> Made with ❤️ Fabio</a>
            </p>
        </div>
    );
};

export default Copyright;
