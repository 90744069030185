import React from 'react';
import { useTranslation } from "react-i18next";

const PageTitleAbout = () => {
    const { t, i18n } = useTranslation();
    return (
        <div id="page-title">
            <div className="wrapper text-center">
                <h1 className="large text-uppercase">{t('header.aboutUs')}</h1>
            </div>
        </div>
    );
};

export default PageTitleAbout;
