import React, { Fragment } from 'react';
import { Helmet } from "react-helmet";
import Loading from '../blocks/loading/Loading';
import Header from '../blocks/header/Header';
import Footer from '../blocks/footer/Footer';
import PageTitleHome from '../blocks/page-title/PageTitleHome';
import ServicesHome from '../blocks/services/ServicesHome';
import AboutsUs from '../blocks/about/AboutUs';
import BookNow from '../blocks/contacts/BookNow';
import Products from '../blocks/products/Products';
import Beard from '../blocks/beard/Beard';

const Home = () => {
    document.body.classList.add('home');
    document.body.classList.add('header-absolute-true');

    return (
        <Fragment>
            <Helmet>
                <meta charSet="UTF-8" />
                <title>Home | {process.env.REACT_APP_WEBSITE_NAME.toString()}</title>

                <meta httpEquiv="x-ua-compatible" content="ie=edge" />
                <meta name="viewport" content="width=device-width, initial-scale=1" />
                <meta name="description" content="Il miglior servizio di taglio parrucchiere, barbiere e styling per uomo nel cuore di Tradate. Scopri i nostri trattamenti di barba e capelli maschili per un look impeccabile." />
                <meta name="keywords" content="Barbiere Uomo, Parrucchiere Maschile, Taglio Capelli, Barba, Rasatura, Barbiere, Parrucchiere" />
                <meta name="robots" content="index, follow, noodp" />
                <meta name="googlebot" content="index, follow" />
                <meta name="google" content="notranslate" />
                <meta name="format-detection" content="telephone=no" />
            </Helmet>
            <Loading />
            <Header logoColor="light" />
            <main id="main" className="site-main content-no-spacing">
                <div className="content">
                    <div className="clearfix">
                        <PageTitleHome />
                        <AboutsUs />
                        <ServicesHome />
                        <Products />
                        <div id="img" className="block spacer">
                            <div className="img-no-wrap-1 img-gray">
                                <div className="img object-fit">
                                    <div className="object-fit-cover">
                                        <img src="assets/img/bg/bg7-min.jpg" alt="bg3" />
                                    </div>
                                </div>
                            </div>
                        </div>
                        {/* <BookNow /> */}
                        <Beard />
                    </div>
                </div>
            </main>

            <Footer />
        </Fragment>
    );
};

export default Home;
